.socialContainer {
    
}

.socialsection {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(0px, auto);
    grid-column-gap: 5px;
    align-items: center;
    width: 60%;
    margin: 0 0 30px 0;
}

.socialContainer > h2 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;
    margin: 0 0 5px 0;
}

@media screen and (max-width: 768px) {
    .socialContainer {
        padding: 0;
    }

    .socialsection {
        width: 20%;
    }
}
