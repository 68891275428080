/*Form styles*/

.form-control {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border: 1.5px solid #000000;
    border-radius: 5px;
    padding: 10px;
    margin-top: 3px;
    margin-bottom: 20px;
    transition: all 0.3s ease-in-out;
}

.form-control:focus {
    outline: none;
    bordeR: 1.5px solid #40E0D0;
    box-shadow: none;
}

.form-label {
    display: block;
    margin: 0 0 0 0;
    font-size: 14px;
}

.blurb {
    margin: 20px 0 20px 0;
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 20px;
}

.form-check label {
    margin: 0 0 0 5px;
}
