.teamCardImage {
    border-radius: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 0.2s ease-in-out;
}

.teamCardStyle {
    border-radius: 15px;
    background-color: #F7F7F7;
    z-index: 0;
}

.teamCardOverlayContainer {
    display: grid;
    align-items: end;
    position: relative;
    width: auto;
    z-index: 1;
}

.teamCardOverlay {
    border-radius: 0 0 15px 15px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 15px;
    transition: all 0.2s ease-in-out;
}

.teamContent {
    display: grid;
    align-content: center;
    position: relative;
    padding: 10% 10% 10% 10%;
    text-align: center;
    z-index: 2;
}

.teamCardStyle, .teamCardOverlayContainer {
    height: 250px;
}

.teamContent {
    height: 150px;
}

.teamContentMargin {
    margin: 0 0 0 0;
}

.teamContent {
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.teamContent p {
    margin: 0 0 10px 0;
}

.icon, .gcicon-linkedin {
    position: absolute;
    bottom: 12px;
    right: 50px;
    font-size: 25px;
    width: 0;
    margin: 0;
}

.teamCardStyle:hover .teamContent {
    opacity: 1;
}

.teamCardStyle:hover .teamCardOverlay {
    height: 150px;
    opacity: 0.9;
}

.iconHoverStyle {
    color: black;
    opacity: 1;
    transition: all 0.2s ease-out;
}

.iconHoverStyle:hover {
    opacity: 0.5;
}

/*Responsive Styles*/

@media screen and (max-width: 991px) {

    /*Team Member Card Styles Mobile*/

    .teamCardOverlay, .teamContent {
        height: 150px;
        bottom: -90px;
        opacity: 1;
    }

    .teamCardStyle:hover .teamCardOverlay {
        opacity: 1;
    }

     .teamCardImage {
        background-position: top;
    }

    /*End Team Card Styles Mobile*/
}

@media screen and (max-width: 768px) {

    .teamCardOverlay, .teamContent {
        bottom: -140px;
    }

    .icon, .gcicon-linkedin {
        position: relative;
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        width: 20%;
    }

    .gcicon-linkedin:before {
        margin: 0!important;
    }
}

@media screen and (max-width: 360px) {

    .teamCardOverlay, .teamContent {
        bottom: -110px;
    }
}
