/*#region declare fonts*/
@font-face {
    font-family: "Roboto";
    src: local("Roboto Regular"), local("Roboto-Regular"), url("../fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Raleway-Bold";
    src: local("Raleway Bold"), local("Raleway-Bold"), url("../fonts/Raleway-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'RobotoCondensedBold';
    src: url('../fonts/RobotoCondensedBold.ttf'), format('truetype');
    font-style: normal;
    font-display: swap;
}

/*#endregion declare fonts*/

/*#region variables */

:root {
    --main-font: "Roboto", sans-serif;
    --header-font: "Raleway-Bold";
    --light-blue-colour: #becbd3;
    --main-font-colour: #1a1a1a;
    --header-font-colour: var(--light-blue-colour);
    --sec-blue-colour: #2d94e4;
    --box-shadow: 0 5px 10px rgb(0, 0, 0, 0.05);

    --marketing-blue: #3a8b94;
    --green-colour: #02BE7C;
    --dark-green: #01402E;
    --dark-blue-colour: #162B44;
    --yellow-colour: #FFC347;
    --teal-colour: #00B1AA;
    --pale-green: #01b18A;
}
/*#endregion variables*/


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 900;
    color: #000000;
}

h1 {
    font-size: 40px;
    line-height: 48px;
}

h2 {
    font-size: 24px;
    line-height: 32px;
}

h3 {
    font-size: 16px;
    line-height: 24px;
}

/*.container {
    position: relative;
    max-width: 1440px;
    padding: 0 5% 0 5%;
    margin: 50px auto;
    z-index: 1;
}*/

.gradientOverlay {
    height: 100%;
    width: auto;
    margin: 0;
    padding: 0;
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 35%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 35%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 35%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
    z-index: 1;
}

/*Colour Styles*/

/*Primary Colour Styles*/
.teal {
    color: #40E0D0;
}
.tealBg {
    background-color: #40E0D0;
}
.blue {
    color: #6495ED;
}
.blueBg {
    background-color: #6495ED;
}
.yellow {
    color: #FFDE00;
}
.yellowBg {
    background-color: #FFDE00;
}
.red {
    color: #FF6A62;
}
.redBg {
    background-color: #FF6A62;
}
.purple {
    color: #8240F0;
}
.purpleBg {
    background-color: #8240F0;
}
.ltPurpleBg {
    background-color: #9F71EC;
}
.cream {
    color: #F9F8EE;
}
.creamBg {
    background-color: #F9F8EE;
}
.ecoGreen {
    color: #A2C69E;
}
.ecoGreenBg {
    background-color: #A2C69E;
}
/*End Primary Colour Styles*/

.white {
    color: #ffffff;
}

.midYellowBg {
    background-color: #FFE852;
}

.midEcoGreenBg {
    background-color: #B3D0AF;
}

.lt-blue-bg {
    background-color: #BBCEF1;
}

.lt-red-bg {
    background-color: #FFC4C1;
}

.cream-bg {
    background-color: #FBFAF3;
}

.lt-grey {
    background-color: #EBE8E8;
}

.blk-bg {
    background-color: #000000;
}

a{
    text-decoration:none;
    color: #000000;
}
a:hover {
    color: var(--marketing-blue);
}

.navbar-dark .navbar-nav .nav-link {
    color: black;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show > .nav-link {
    color: black;
}

.dropdown-toggle::after {
    display: none;
}

.navbar-dark .navbar-toggler {
    border: 1px solid black;
    background-color: black;
}

@media screen and (max-width: 991px) {
    .navbar-nav {
        flex-direction: unset;
        justify-content: start;
        width: 100%;
    }
   
}

@media screen and (max-width: 768px) {
   
}

@media screen and (max-width: 425px) {
   
}