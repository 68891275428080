
.sectorListContainer {
    border-top: solid 1px black;
    width: 50%;
    padding: 30px 0 0 0;
    transition: all .25s ease-in-out;
}
.sectorListContainer.grow {
    width: 100%;
}

.sectorList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0em;
    column-gap: 4em;
    align-items: start;
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    filter: opacity(0);
    transition: all .25s ease-in-out;
}

.sectorList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.sectorList.active {
    opacity: 1;
    filter: opacity(100);
    height: auto;
    max-height: 805px;
}
.sectionListItem {
    position: relative;
    margin: 0 0 0 20px;
    z-index: 1;
}
.sectorListItemContainer {
    display: grid;
    align-content: center;
    position: relative;
    height: 50px;
    z-index: 0;
}
.sectorListOverlay {
    position: absolute;
    left: 0px;
    height: 0px;
    width: 6px;
    height: 100%;
    transition: all 0.2s ease-in-out;
}

li:hover .sectorListOverlay {
    width: 100%;
}


.icon {
    display: inline-block;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.icon.rotate {
    transform: rotate(90deg);
}

.pointer {
    cursor: pointer;
}

.midGrey {
    color: #9A9898;
}

@media screen and (max-width: 768px) {

    .sectorList {
        grid-template-columns: repeat(2, 1fr) !important;
        column-gap: 2em !important;
    }
    .sectorListItemContainer {
        height: 70px !important;
    }
}

@media screen and (max-width: 425px) {

    h1 {
        font-size: 10vw !important;
        line-height: 120%;
    }
}

@media screen and (max-width: 326px) {
    .sectorList {
        grid-template-columns: 1fr !important;
    }
}