.homeContainer {
   /* display: flex;
    flex-direction: column;*/
    /*padding: 0 5% 0 5%;*/
}

/*Sector Styles*/
.sectorMarker {
    font-size: 14px;
    font-weight: 900;
    transition: all 0.2s ease-in-out;
}

.sectorName {
    font-size: 14px;
    text-decoration: underline;
}

.date {
    font-weight: 500;
}

section {
    position: relative;
    max-width: 1440px;
    padding: 0 5% 0 5%;
    margin: 50px auto;
    z-index: 1;
}

section.sectionContainer {
     display: grid;
     grid-template-columns: repeat(2, 1fr);
     grid-auto-rows: minmax(100px, auto);
     grid-gap: 5em;
     align-items: center;
}

.noMarginTb {
    margin: 0 auto;
}

section h1,
    h2,
    h3,
    p {
        margin: 0 0 30px 0;
}

/*section img {
     border-radius: 15px;
     width: 100%;
}*/

.imageBg {
    border-radius: 15px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.imageHeight1 {
    height: 562px;
}

section h1 {
     font-size: 40px;
     line-height: 48px;
     font-weight: 900;
 }

.sectionWorkWithUs {
    max-width: 1440px;
    padding: 0 5% 0 5%;
    margin: 50px auto;
}

.sectionWorkWithUs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5em;
    align-items: center;
}

.sectionWorkWithUs h3 {
        font-weight: 400;
    }

.sectionWorkWithUsContainer {
    border-radius: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0;
}

.sectionWorkWithUsContainer .gradientOverlay {
    border-radius: 15px;
    padding: 50px;
}

.nested {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5em;
    align-items: center;
}

.carouselContainer {
    position: relative;
    width: 100%;
    height: auto;
    padding: 1px 0 50px 0;
}

.gradientOverlay {
    height: 100%;
    width: auto;
    margin: 0;
    padding: 0;
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 35%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 35%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 35%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
    z-index: 1;
}

/*News Card Styles*/

.newsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 3em;
    align-items: initial;
}

.newsCardImage {
    border-radius: 15px 15px 0 0;
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 140%;
    transition: all 0.2s ease-in-out;
}

.newsCardStyle {
    border-radius: 15px;
    background-color: #F7F7F7;
    z-index: 0;
}

.newsIdxContainer {
    display: grid;
    grid-template-rows: auto;
}

.newsIdxContainer div {
    display: grid;
    justify-content: start;
}

.cardOverlayContainer {
    position: relative;
    width: auto;
    z-index: 1;
}

.cardOverlay {
    border-radius: 0 0 0 15px;
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 100%;
    transition: all 0.2s ease-in-out;
}

.newsContent {
    position: relative;
    padding: 40px;
    z-index: 2;
}

.newsContentMargin {
    margin: 0 0 15px 0;
}

.newsContent h3 {
    text-decoration: underline;
}

.newsCardStyle:hover .cardOverlay {
    width: 100%;
    border-radius: 0 0 15px 15px;
}

.newsCardStyle:hover .newsCardImage {
    background-size: 150%;
}

.newsCardStyle:hover .sectorMarker {
    color: black;
}

/*Colour Styles*/
.teal {
    color: #40E0D0;
}

.tealBg {
    background-color: #40E0D0;
}

.blue {
    color: #6495ED;
}

.blueBg {
    background-color: #6495ED;
}

.yellow {
    color: #FFDE00;
}

.yellowBg {
    background-color: #FFDE00;
}

.ltYellowBg {
    background-color: #FFF29A;
}

.red {
    color: #FF6A62;
}

.redBg {
    background-color: #FF6A62;
}

.purple {
    color: #8240F0;
}

.purpleBg {
    background-color: #8240F0;
}

.cream {
    color: #F9F8EE;
}

.creamBg {
    background-color: #F9F8EE;
}

.ecoGreen {
    color: #A2C69E;
}

.ecoGreenBg {
    background-color: #A2C69E;
}

.ltGreyBg {
    background-color: #F7F7F7;
}

.midGrey {
    color: #9A9898;
}

.midGreyBg {
    background-color: #9A9898;
}

.ltTealBg {
    background-color: #8AE3DA;
}

.cameoGreenBg {
    background-color: #E0DCB6;
}

.pinkBg {
    background-color: #F3AAD1;
}

.orangeBg {
    background-color: #FFA462;
}

.ltBlueBg {
    background-color: #8DAEE8;
}

.ltRedBg {
    background-color: #FFC4C1;
}

.white {
    color: #ffffff;
}

.whiteBg {
    background-color: #ffffff;
}
/*End Colour Styles*/

@media screen and (max-width: 1024px) {
    .imageHeight1 {
        height: 755px;
    }

}

    @media screen and (max-width: 991px) {

        .sectionContainer {
            grid-template-columns: 1fr !important;
            grid-gap: 2em !important;
        }

        .newsContainer {
            grid-template-columns: repeat(2, 1fr) !important;
            grid-gap: 2em !important;
        }

        .imageHeight1 {
            height: 400px;
        }
    }

    @media screen and (max-width: 768px) {
        .newsContainer {
            grid-template-columns: 1fr !important;
        }
    }

    @media screen and (max-width: 425px) {

        .sectionWorkWithUsContainer .gradientOverlay {
            padding: 30px;
        }

        .nested {
            grid-template-columns: 1fr !important;
            grid-gap: 0em !important;
        }

        h1 {
            font-size: 10vw !important;
            line-height: 120% !important;
        }

        .sectionWorkWithUsContainer h2 {
            font-size: 8vw !important;
            line-height: 110% !important;
        }

        .imageHeight1 {
            height: 200px;
        }
    }


