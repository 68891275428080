.navContainer {
    position: fixed;
    z-index: 3000;
    width: 100%;
}

.navbar {
    /*position: fixed;*/
    padding: 14.5px 5% 14.5px 5%;
    background-color: #40E0D0;
    color: black;
    width: 100%;
    /*z-index: 3000;*/
    --bs-navbar-nav-link-padding-x: 0;
    /*--bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%273%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
    --bs-navbar-toggler-border-color: none;
    --bs-navbar-toggler-focus-width: 0rem;*/
}

.navbar.relative {
    position: relative;
}

/*Animated burger menu styles*/
.navbarToggler {
    display: grid;
    align-items: center;
    width: 20px;
    height: 20px;
    position: relative;
    transition: .5s ease-in-out;
}

.navbarToggler,
.navbarToggler:focus,
.navbarToggler:active,
.navbarToggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 0;
}

.navbarToggler span {
    margin: 0;
    padding: 0;
}

.togglerIcon {
    display: grid;
    align-self: center;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #000000;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.middleBar {
    margin-top: 0;
}

/*when navbar is clicked*/

/*.navbarToggler .topBar {
    margin-top: 0;
    transform: rotate(135deg);
}

.navbarToggler .middleBar {
    opacity: 0;
    filter: opacity(0);
}

.navbarToggler .bottomBar {
    margin-top: 0;
    transform: rotate(-135deg);
}*/

/*state when the navbar is collapsed*/

.navbarToggler.collapsed .topBar {
    margin-top: -20px;
    transform: rotate(0deg);
}

.navbarToggler.collapsed .middleBar {
    opacity: 1;
    filter: opacity(100);
}

.navbarToggler.collapsed .bottomBar {
    margin-top: 20px;
    transform: rotate(0deg);
}

/*active state when clicked*/

.navbarToggler.active .topBar {
    margin-top: 0;
    transform: rotate(135deg);
}

.navbarToggler.active .middleBar {
    opacity: 0;
    filter: opacity(0);
}

.navbarToggler.active .bottomBar {
    margin-top: 0;
    transform: rotate(-135deg);
}


/*End animated burger menu styles*/
.brand {
    display: flex;
    justify-content: flex-start;
}

.logo {
    height: 20px;
}

.navLinkContainer {
    display: flex;
    justify-content: flex-end;
}
.navItemsContainer {
    display: flex;
    align-items: center;
    gap: 30px;
}

.current::after {
 /*   content: "";
    display: block;
    position: absolute;
    bottom: -21px;
    height: 5px;
    width: 100%;
    color: black;
    background-color: black;*/
}

.current div {
    position: absolute;
    bottom: -20px;
    width: 100%;
    height: 5px;
    background-color: black;
}

.navLink {
    display: flex;
    justify-content: center;
    position: relative;
    font-size: 16px;
    color: black;
    font-weight: 700;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
}

.navLink::after {
    content: "";
    position: absolute;
    bottom: -20px;
    height: 5px;
    width: 0;
    background-color: black;
    transition: all 0.2s ease-in-out;
 }

.navLink:hover::after {
    width: 100%;
}

a:hover {
    color: black;
}

@media screen and (max-width: 991px) {

    .navItemsContainer {
        display: block;
        margin: 25px 0 0 0;
    }

    .navLink {
        justify-content: left !important;
        font-size: 26px !important;
        line-height: 34px !important;
        margin: 0 !important;
        padding: 0 0 18px 0 !important;
        color: #000000 !important;
        opacity: 0.5;
    }

    .navLink::after {
        display: none;
    }

    .current div {
        display: none;
    }
 
}

@media screen and (max-width: 768px) {
   
}

@media screen and (max-width: 425px) {

    
}
