.cardOverlayContainer {
    display: grid;
    align-items: initial;
    position: relative;
    width: auto;
    border-radius: 15px;
    z-index: 0;
}

.cardBgLtGrey {
    background-color: #F7F7F7;
}

.cardBgWhite {
    background-color: #ffffff;
}

.newsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 3em;
    align-items: initial;
}

.newsIdxContainer {
    display: grid;
    grid-template-rows: auto;
}

.newsCardImage {
    position: relative;
    border-radius: 15px 15px 0 0;
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 5;
    transition: all 0.2s ease-in-out;
}

.newsCardStyle {
    border-radius: 15px;
    z-index: 0;
}

.cardOverlay {
    border-radius: 15px 0 0 15px;
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 100%;
    transition: all 0.2s ease-in-out;
    z-index: 0;
}

.newsContent {
    position: relative;
    padding: 40px;
    z-index: 2;
}

.newsContentMargin {
    margin: 0 0 15px 0;
}

.newsContent h3 {
    text-decoration: underline;
}

.cardOverlayContainer:hover .cardOverlay {
    width: 100%;
    border-radius: 15px;
}

.cardOverlayContainer:hover .sectorMarker {
    color: black;
}

.newsCardLink {
    display: grid;
}

.buttonContainer {
    align-self: end;
    position: relative;
    z-index: 6;
    padding: 0 40px 40px 40px;
}

/*Sector Styles*/
.sectorMarker {
    font-size: 14px;
    font-weight: 900;
    transition: all 0.2s ease-in-out;
}

.sectorName {
    font-size: 14px;
    text-decoration: underline;
}

.date {
    font-weight: 500;
}

.sectorContainer {
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 0 50px 0;
}

.firstNewsItem {
    display:grid;
    grid-column: 1/3;
}

.firstNewsItem .newsCardImage {
    height: 350px;
}

/*Responsive styles*/

@media screen and (max-width: 991px) {
    .newsSectionTitle {
        width: 100% !important;
    }

    .newsContainer {
        grid-template-columns: repeat(2, 1fr) !important;
        grid-gap: 2em !important;
    }
}

@media screen and (max-width: 768px) {
    .newsSectionTitle h1 {
        font-size: 32px !important;
        line-height: 40px !important;
    }

    .newsContainer {
        grid-template-columns: 1fr !important;
    }

    .firstNewsItem {
        grid-column: 1/1 !important;
    }

    .firstNewsItem .newsCardImage {
        height: 200px !important;
    }

}

@media screen and (max-width: 425px) {

    h1 {
        font-size: 10vw !important;
        line-height: 120%;
    }
}

@media screen and (max-width: 313px) {

    .newsPageContainer {
        padding: 80px 0 0 0 !important;
    }
}