
.resourceContainer {
    border-radius: 15px;
    padding: 6%;
    z-index: 0;
    margin: 0 0 30px 0;
}

.resourceContentMargin {
    margin: 0 0 10px 0;
}

.resourceContentStyles {
    position: relative;
    max-width: 1440px;
    padding: 0 5% 0 5%;
    margin: 50px auto;
    z-index: 1;
}

.resourceContentStyles h1 {
    margin: 0 0 10px 0;
}

.resourceContentStyles h2 {
    font-weight: 300;
}

.linkContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2em;
    align-items: center;
}

.linkContainer ul {
    padding-inline-start: 20px;
    margin: 0 0 0 0;
}

.linkContainer ul li {
    margin: 0 0 10px 0;
    text-decoration: underline;
}

/*Responsive Styles*/

@media screen and (max-width: 991px) {

    .linkContainer {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 0;
    }

}

@media screen and (max-width: 768px) {
    .resourceContainer {
        padding: 50px;
    }
   
}

@media screen and (max-width: 425px) {
    .resourceContainer {
        padding: 40px;
    }
    .linkContainer {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
}

@media screen and (max-width: 360px) {
    .resourceContainer {
        padding: 25px;
    }

}

@media screen and (max-width: 326px) {

    
}
