.carouselWrapper {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5em;
    align-items: center;
    border-radius: 15px;
}

.sectorMarker {
    font-weight: 900;
}

.eventsCardStyle:hover .sectorMarker {
    color: black;
}

.eventBannerBgLtGrey {
    background-color: #F7F7F7;
}

.eventBannerBgWhite {
    background-color: #ffffff;
}

/*Mobile Styles*/

@media screen and (max-width: 991px) {
    .carouselWrapper {
        grid-template-columns: 1fr !important;
        grid-gap: 2em !important;
        grid-gap: 0em !important;
    }
}