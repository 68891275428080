.pageContainer {
    position: relative;
    display:flex;
    flex-direction:column;
    gap:0px;
}

.pageContainer h1 {
     font-weight: 900;
     padding: 30px 0 0 0;
}

.pageContainer h1,
    h2,
    h3,
    p,
    ul {
        margin: 0 0 30px 0;
    }

.pageContainer a {
    flex-basis: auto;
}

.sectionContainer5 {
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 0 50px 0;
}

    .sectionContainer5 h1,
    h2,
    h3,
    p {
        margin: 0 0 30px 0;
    }

    .sectionContainer5 h1 {
        font-weight: 900;
        margin: 0 0 30px 0;
    }

.workWithUs_Section5 {
    position: relative;
    max-width: 1440px;
    padding: 0% 5% 0% 5%;
    margin: 50px auto;
}

.workWithUs_Section5 {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5em;
    align-items: center;
}

.image5 {
    border-radius: 15px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


.image5 {
    height: 400px;
}

/*End Who we are page styles*/

/*Team Member Card Styles*/

.teamContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 2em;
    align-items: center;
}

/*.teamContainer {
    position: relative;
    max-width: 1440px;
    padding: 0% 5% 0% 5%;
    margin: 50px auto;
}*/

.teamCardImage {
    border-radius: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    transition: all 0.2s ease-in-out;
}

.teamCardStyle {
    border-radius: 15px;
    height: 250px;
    background-color: #F7F7F7;
    z-index: 0;
}

.teamCardOverlayContainer {
    position: relative;
    height: 250px;
    width: auto;
    z-index: 1;
}

.teamCardOverlay {
    border-radius: 0 0 15px 15px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 15px;
    transition: all 0.2s ease-in-out;
}

.teamContent {
    display: grid;
    align-content: end;
    position: relative;
    height: 250px;
    padding: 10% 10% 20% 10%;
    text-align: center;
    z-index: 2;
}

.teamContentMargin {
    margin: 0 0 0 0;
}

.teamContent {
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

    .teamContent:hover {
        opacity: 1;
    }

.teamCardStyle:hover .teamCardOverlay {
    height: 60%;
}

.teamCardImage:hover {
    background-size: 110%;
}

/*End Team Card Styles*/

/*Colour Styles*/
.teal {
    color: #40E0D0;
}

.tealBg {
    background-color: #40E0D0;
}

.blue {
    color: #6495ED;
}

.blueBg {
    background-color: #6495ED;
}

.yellow {
    color: #FFDE00;
}

.yellowBg {
    background-color: #FFDE00;
}

.ltYellowBg {
    background-color: #FFF29A;
}

.red {
    color: #FF6A62;
}

.redBg {
    background-color: #FF6A62;
}

.purple {
    color: #8240F0;
}

.purpleBg {
    background-color: #8240F0;
}

.ltPurpleBg {
    background-color: #CDB2F9;
}

.cream {
    color: #F9F8EE;
}

.creamBg {
    background-color: #F9F8EE;
}

.ecoGreen {
    color: #A2C69E;
}

.ecoGreenBg {
    background-color: #A2C69E;
}

.ltGreyBg {
    background-color: #F7F7F7;
}

.midGrey {
    color: #9A9898;
}

.midGreyBg {
    background-color: #9A9898;
}

.ltTealBg {
    background-color: #B0F2EC;
}

.cameoGreenBg {
    background-color: #E0DCB6;
}

.ltCameoGreenBg {
    background-color: #C7DDC5;
}

.pinkBg {
    background-color: #F3AAD1;
}

.orangeBg {
    background-color: #FFA462;
}

.ltBlueBg {
    background-color: #8DAEE8;
}

.ltRedBg {
    background-color: #FFC4C1;
}

.whiteBg {
    background-color: #ffffff;
}
/*End Colour Styles*/

/*Responsive Styles*/

@media screen and (max-width: 3840px) {

    .sectionContainer3 {
        top: 1422px !important;
    }

    .sectionContainer4 {
        top: 2035px !important;
    }

}

@media screen and (max-width: 1026px) {
    
    .sectionContainer2 {
        top: 873px !important;
    }

    .sectionContainer3 {
        top: 1557px !important;
    }

    .sectionContainer4 {
        top: 2035px !important;
    }
}

@media screen and (max-width: 991px) {
    
    .image1 {
        height: 840px !important;
    }

    .sectionContainer2 {
        top: 820px !important;
    }

    .sectionContainer3 {
        top: 1500px;
    }

    .sectionContainer4 {
        top: 2066px !important;
    }

    .teamContainer {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 2em;
        grid-row-gap: 7em;
        margin: 0 0 150px 0;
    }

}

@media screen and (max-width: 985px) {

    .sectionContainer2 {
        top: 848px !important;
    }
}

@media screen and (max-width: 885px) {  

    .sectionContainer2 {
        top: 874px !important;
    }

    .image1 {
        height: 900px !important;
    }
}

@media screen and (max-width: 872px) {

    .sectionContainer2 {
        top: 915px !important
    }

    .sectionContainer3 {
        top: 1617px !important;
    }

    .sectionContainer4 {
        top: 2138px !important;
    }
}

@media screen and (max-width: 844px) {
    
    .sectionContainer4 {
        top: 2197px !important;
    }
}

@media screen and (max-width: 808px) {

    .sectionContainer2 {
        top: 986px !important;
    }

    .sectionContainer3 {
        top: 1706px !important
    }

    .sectionContainer4 {
        top: 2300px !important
    }
}

@media screen and (max-width: 788px) {

    .sectionContainer2 {
        top: 986px !important;
    }

    .sectionContainer3 {
        top: 1742px !important;
    }

    .sectionContainer4 {
        top: 2358px !important;
    }
}

@media screen and (max-width: 768px) {

    .whoWeAre_Section1, .whoWeAre_Section2, .whoWeAre_Section3, .whoWeAre_Section4, .workWithUs_Section5 {
        grid-template-columns: 1fr !important;
        grid-gap: 2em !important;
    }

    .whoWeAre_Section1, .whoWeAre_Section2, .whoWeAre_Section3, .whoWeAre_Section4 {
        margin: 0px auto !important;
    }

    .sectionContainer5 {
        padding-top: 1px !important;
    }

    .image1, .image2, .image3 {
        height: 400px !important;
    }

    .sectionContainer1, .sectionContainer2, .sectionContainer3, .sectionContainer4 {
        position: relative !important;
        top: auto !important;
    }

    .image2, .image4 {
        grid-row: 2/3 !important;
    }

    .teamContainer {
        grid-template-columns: 1fr;
        grid-row-gap: 10em;
    }
}

@media screen and (max-width: 568px) {
    .pageContainer a {
        flex-grow: 1;
    }
}

@media screen and (max-width: 425px) {

    .teamContainer {
        grid-template-columns: 1fr !important;
    }

    .image1, .image2, .image3, .image4, .image5 {
        height: 250px !important;
    }

    h1 {
        font-size: 10vw !important;
        line-height: 120%;
    }

    .image5 {
        height: 200px;
    }
}

@media screen and (max-width: 360px) {

    .teamContainer {
        grid-row-gap: 8em;
    }

}

@media screen and (max-width: 326px) {

    .exContentHeight {
        height: 304px !important;
    }
}
