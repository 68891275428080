.eventsPageContainer {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 0% 5% 0 5%;
    margin: 0 auto;
}

.eventsPageContainer {
    padding-top: 100px;
}

.eventsContainerTop {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(0px, auto);
    grid-column-gap: 1em;
    align-items: end;
}

.eventsContainerTop div:last-child {
    display: grid;
    grid-column: 1/3;
}

.eventsHeroImage {
    border-radius: 15px;
    width: 100%;
    height: 400px;
    grid-column: 1/3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.eventsItemContentContainer {
    display: grid;
    grid-template-columns: 6fr 2fr;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 0em;
    align-items: start;
    margin: 50px 0 50px 0;
}

.eventsItemContentleft {
    border-right: 1px solid #000000;
    padding: 0 50px 0 0;
}

.eventDetailsContainer {
    border-bottom: 1px solid #CBCBCB;
    margin: 0 0 30px 0;
    padding: 0 0 20px 0;
}

.eventDetailsContainer p {
    margin:0;
}

.eventContentContainer {
}

.eventContentContainer h1 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 900;
    margin: 0 0 30px 0;
}

.eventContentContainer h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 900;
}

.eventContentContainer h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}

.eventContentContainer img {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.eventContentContainer iframe {
    width: 100%;
    height: 400px;
    border-radius: 15px;
}

.eventContentContainer span p a {
    font-weight: bold;
    color: #40E0D0;
    text-decoration: underline;
    overflow-wrap: anywhere;
}

/*Form styles*/
.formContainer {
    margin: 0 0 50px 0;
    padding: 0 0 0 0;
}

input, textarea {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #000000;
    border-radius: 0;
    padding: 10px;
    margin-top: 3px;
    margin-bottom: 20px;
    transition: all 0.5s ease-in-out;
}

input:focus, textarea:focus {
    outline: none;
    border-bottom: 1.5px solid #40E0D0;
}

.submit {
    display: inline;
    border: 3px solid #000000;
    border-radius: 15px;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    padding: 18px 15px 21px 18px;
    margin: 20px 0 0 0;
    width: 180px;
    font-weight: 500;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    transition-property: all 0.3s;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

.submit:hover {
    border: 3px solid #000000;
    background: none;
    color: #000000;
}

.disclaimerText {
    font-size: 14px;
    margin: 20px 0 0 0;
}

/*Aside styles*/

.eventSocialContainer {
    border-bottom: 1px solid #CBCBCB;
    margin: 0 0 50px 0;
}

.eventsAsideContainer {
    padding: 0 0 0 40px;
}

.eventsCardStyle {
    z-index: 0;
}

.cardOverlayContainer {
    position: relative;
    width: auto;
    z-index: 1;
}

.cardOverlay {
    border-radius: 0 0 0 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 100%;
    transition: all 0.2s ease-in-out;
}

.eventsContent {
    position: relative;
    padding: 40px;
    height: auto;
    z-index: 2;
}
.eventsContent p{
    margin:0;
}

.eventsContentMargin {
    margin: 0 0 15px 0 !important;
}

.eventsContentExMargin {
    margin: 0 0 80px 0 !important;
}

.noMargin {
    margin: 0;
}

.eventsContent h2 {
    text-decoration: underline;
}

.eventsCardStyle:hover .cardOverlay {
    width: 100%;
}

/*Sector Styles*/
.sectorMarker {
    font-size: 14px;
    font-weight: 900;
    transition: all 0.2s ease-in-out;
}

.sectorName {
    font-size: 14px;
    text-decoration: underline;
}

.eventsCardStyle:hover .sectorMarker {
    color: black;
}

/*End Aside events Card Styles*/
/*Colour Styles*/
.teal {
    color: #40E0D0;
}

.tealBg {
    background-color: #40E0D0;
}

.blue {
    color: #6495ED;
}

.blueBg {
    background-color: #6495ED;
}

.yellow {
    color: #FFDE00;
}

.yellowBg {
    background-color: #FFDE00;
}

.red {
    color: #FF6A62;
}

.redBg {
    background-color: #FF6A62;
}

.purple {
    color: #8240F0;
}

.purpleBg {
    background-color: #8240F0;
}

.cream {
    color: #F9F8EE;
}

.creamBg {
    background-color: #F9F8EE;
}

.ecoGreen {
    color: #A2C69E;
}

.ecoGreenBg {
    background-color: #A2C69E;
}

.ltGreyBg {
    background-color: #F7F7F7;
}

.midGrey {
    color: #9A9898;
}

.midGreyBg {
    background-color: #9A9898;
}

.ltTealBg {
    background-color: #8AE3DA;
}

.cameoGreenBg {
    background-color: #E0DCB6;
}

.pinkBg {
    background-color: #F3AAD1;
}

.orangeBg {
    background-color: #FFA462;
}

.ltBlueBg {
    background-color: #8DAEE8;
}

.whiteBg {
    background-color: #ffffff;
}
/*End Colour Styles*/

.normal {
    font-weight: 400;
}

.bold {
    font-weight: 500;
}

.medium {
    font-weight: 700;
}

.exBold {
    font-weight: 900;
}

.eventIconStyle {
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 15px 0 !important;
}

/*Responsive styles*/

@media screen and (max-width: 991px) {

   
}

@media screen and (max-width: 768px) {
    .eventsItemContentContainer {
        grid-template-columns: 1fr !important;
        grid-gap: 2em;
    }

    .eventsItemContentleft {
        border-right: none !important;
        padding: 0 !important;
    }

    .eventsHeroImage {
        height: 300px !important;
    }

    .eventsAsideContainer {
        padding: 0;
    }

    .eventContentContainer a {
        /*font-size: 2.5vw !important;*/
        /*line-height: 120%;*/
    }

}

@media screen and (max-width: 425px) {

    .eventsHeroImage {
        height: 250px !important;
    }

    .eventsPageContainer {
        padding-top: 80px !important;
    }

    .eventsItemContentContainer {
        margin-top: 20px !important;
    }

    .eventsContentExMargin {
        margin: 0 0 30px 0 !important;
    }

    .eventContentContainer iframe {
        height: 300px !important;
    }
   
}

@media screen and (max-width: 326px) {

   
}

@media screen and (max-width: 313px) {

    
}
