.heroContainerParallax, .subHeroContainerParallax {
    position: relative;
    display: grid;
    place-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #40E0D0;
    overflow: hidden;
    z-index: 0;
}

.heroImageBg {
    position: absolute;
    inset: 0;
    background-position: center;
    background-size: cover;
}

.heroImageBg {
    z-index: 0;
}

.hide {
    display: none;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
}

/*.heroContainer, .subHeroContainer {
    width: 100%;
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment:fixed;
    overflow: hidden;
    z-index: 0;
}*/

/*.heroContainer {
    height: 100vh;
}
*/
/*.subHeroContainer {
    height: 60vh;
 }*/

.heroContentContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 0em;
    align-items: center;
    padding: 5% 8% 5% 8%;
    z-index: 15;
}

.gradientOverlay, .subGradientOverlay {
    width: 100%;
    margin: 0;
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 35%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 35%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 35%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
    z-index: 1;
}

.gradientOverlay, .subGradientOverlay {
    display: grid !important;
    grid-template-columns: 1fr !important;
    align-items: center;
    justify-content: left !important;
    padding: 78.6px 0 0 0;
}

/*.subHeroContainer > .gradientOverlay {
    height: 60vh;
}
*/
.heroContent h1 {
        font-size: 60px;
        font-weight: 900;
        line-height: 65px;
        margin: 0 0 30px 0;
    }

.heroContent h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin: 0 0 30px 0;
}

/*.heroContent > a:first-of-type button {
    margin: 0 20px 0 0;
}*/

.heroContent > div a:first-of-type button {
    margin: 0 20px 0 0;
}

.subHeroContainerParallax h1 {
    margin: 0px;
}

/*Resposive styles*/
@media screen and (max-width: 991px) {
    .subHeroContainerParallax, .subGradientOverlay {
        height: 50vh !important;
    }

    .heroContentContainer {
        grid-template-columns: 3fr 1fr !important;
        grid-gap: 2em !important;
        margin: 0px !important;
        padding: 5% !important;
    }

    .gradientOverlay, .subGradientOverlay {
        display: grid !important;
        grid-template-columns: 1fr !important;
        justify-content: left !important;
        padding-top: 59px !important;
    }

    .mobHide {
        display: none !important;
        visibility: hidden !important;
    }

  
}

@media screen and (max-width: 768px) {
 
}

@media screen and (max-width: 425px) {

    .heroContentContainer {
        grid-template-columns: 1fr !important;
        grid-gap: 0em !important;
    }


  .heroContent h1 {
        font-size: 11vw !important;
        line-height: 120% !important;
    }

    .heroContentContainer {
        padding: 5% 10% 2% 10%;
    }

    .subHeroContainerParallax, .subGradientOverlay {
        height: 45vh !important;
    }

    .heroContent > div a:first-of-type button {
        margin: 0 0 30px 0 !important;
        background-color: white !important;
        color: black !important;
    }

/*    .heroContainer, .subHeroContainer {
        background-attachment: scroll;
    }*/

}

@media screen and (max-width: 326px) {

    .heroContentContainer {
        padding-top: 10%  !important;
    }


}
