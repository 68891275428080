
.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -40px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: left;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 2%;
    list-style: none;
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #ffffff;
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 100px;
    height: 0;
    padding: 1.5px 6px;
    margin: 5px 2.5px;
    text-indent: -999px;
    cursor: pointer;
    background-clip: padding-box;
    border-radius: 30px;
    border: solid 1px black;
    transition: all 0.4s ease-in-out;
}

.carousel-indicators button.active {
    width: 100px;
    height: 0;
    background-color: #000000 !important;
    margin: 5px 2.5px;
    border-radius: 30px;
    border: solid 1px black;
    padding: 1.5px 6px;
    transition: all 0.4s ease-in-out;
}

.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 230px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 0;
    color: #000000;
    text-align: center;
    background: 0 0;
    background-color: #ffffff;
    border: 0;
    opacity: 1;
    filter: drop-shadow(1px 1px 1px grey);
    transition: opacity .15s ease;
}

.carousel-control-prev {
    left: -24px;
}

.carousel-control-next {
    right: -24px;
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    color: #000;
    text-decoration: none;
    outline: 0;
    opacity: 1;

 }

.carousel-control-next, .carousel-control-prev {
        /*padding-right: 0px;*/
        transition: all 0.2s ease-out;
}

.carousel-control-prev:hover {
        padding-right: 10px;
}

.carousel-control-next:hover {
        padding-left: 10px;
    }

@media screen and (max-width: 991px) {
   
  
}

@media screen and (max-width: 768px) {
    
}

@media screen and (max-width: 425px) {
  /*  .carousel-indicators [data-bs-target] {
        width: 30px !important;
    }*/
   
}
