.revealSlideContentContainer {
    position: relative;
    overflow: hidden;
}

.revealContentContainer {
    position: relative;
    /*overflow: hidden;*/
}

.slideContentContainer {
    position: relative;
    overflow: hidden;
}

.fadeInContentContainer {
    position: relative;
}

.slideContentContainer, .fadeInContentContainer > div {
    display: grid;
    height: 100%;
}

/*@media screen and (max-width: 768px) {
    .slideContentContainer > div {
        height: auto;
    }
}*/
