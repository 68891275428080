.footerContainer {
    position: relative;
    width: 100%;
    height: auto;
    padding: 1px 0 1px 0;
    background-color: #000000;
}

.sectionFooterContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 1.5em;
    align-items: stretch;
    border-top: solid 1px rgba(255, 255, 255, 0.4);
    padding-top: 1.5em;
}


.footerContent1, .footerContent2 {
    border-right: solid 1px rgba(255, 255, 255, 0.4);
    padding-right: 1.5em;
}

.footerContent4 {
    justify-self: end;
}

.sectionFooterContainer {
}

.sectionFooterContainer p {
    margin: 0 0 10px 0;
}

.hoverStyle {
        color: white;
        transition: all 0.2s ease-out;
    }

.hoverStyle:hover {
        color: #40E0D0;
    }

.logo {
    width: 34%;
    height: auto;
    border-radius: 0;
}

.lveplogo {
    width: 266px;
    height: auto;
    margin: 54px 0 0 0;
    border-radius: 0;
}

.footerBottomContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    grid-gap: 5em;
    align-items: start;
    border-top: solid 1px rgba(255, 255, 255, 0.4);
    margin: 1.5em 0 0 0;
    padding: 10px 0 0 0;
}

@media screen and (max-width: 1251px) {
    .sectionFooterContainer {
        grid-template-columns: repeat(2, 1fr) !important;
        grid-gap: 1.5em !important;
    }

    .footerContent2 {
        border-right: none !important;
        padding-right: 0 !important;
    }

    .footerContent3 {
        border-right: solid 1px rgba(255, 255, 255, 0.4);
        padding-right: 1.5em;
    }

    .lveplogo {
        margin:0 !important;
    }

    .footerContent4 {
        justify-self: start;
    }
}

@media screen and (max-width: 991px) {
    .logo {
        width: 70%;
    }
}

@media screen and (max-width: 768px) {

    .sectionFooterContainer {
        grid-template-columns: 1fr !important;
    }

    .footerContent1, .footerContent3 {
        border-right: none !important;
        padding-right: 0 !important;
    }

    .footerContent1, .footerContent2, .footerContent3 {
        border-bottom: solid 1px rgba(255, 255, 255, 0.4) !important;
        padding-bottom: 1.5em !important;
    }

    .footerBottomContainer {
        grid-template-columns: 4fr 2fr !important;
        grid-gap: 0.5em !important;
    }
}

@media screen and (max-width: 425px) {
    .logo, .lveplogo {
        width: 100% !important;
    }
}