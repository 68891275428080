.contactPageContainer {
    display: flex;
    flex-direction: column;
    max-width: 1080px;
    padding: 0% 5% 5% 5%;
    margin: 0 auto;
}

.contactPageContainer {
    padding-top: 100px;
}

.contactHeroImage {
    border-radius: 15px;
    width: 100%;
    height: 400px;
    grid-column: 1/3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.contactContentContainer {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 0em;
    align-items: start;
    margin: 50px 0 50px 0;
}

.contactContentContainer a {
    font-weight: 400;
    text-decoration: underline;
    overflow-wrap: anywhere;
}

.contactContentleft {
    padding: 0 50px 0 0;
}

.contactContentRight {
    border-left: 1px solid #000000;
    padding: 0 0 0 50px;
}
.contactContentRight button {
    width: 40%;
}



/*Form styles*/
input, textarea {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #000000;
    border-radius: 0px;
    padding: 10px;
    margin-top: 3px;
    margin-bottom: 20px;
    transition: all 0.5s ease-in-out;
}

input:focus, textarea:focus {
    outline: none;
    border-bottom: 1.5px solid #40E0D0;
}

.submit {
    display: inline;
    border: 3px solid #000000;
    border-radius: 15px;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    padding: 18px 15px 21px 18px;
    margin: 20px 0 0 0;
    width: 180px;
    font-weight: 500;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    transition-property: all 0.3s;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

.submit:hover {
        border: 3px solid #000000;
        background: none;
        color: #000000;
}

.disclaimerText {
    font-size: 14px;
    margin: 20px 0 0 0;

}


/*Aside styles*/

.eventsCardStyle {
    z-index: 0;
}

.cardOverlayContainer {
    position: relative;
    width: auto;
    z-index: 1;
}

.cardOverlay {
    border-radius: 0 0 0 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 100%;
    transition: all 0.2s ease-in-out;
}

.eventsContent {
    position: relative;
    padding: 40px;
    height: auto;
    z-index: 2;
}

.contactContentMargin {
    margin: 0 0 15px 0;
}

.contactContentExMargin {
    margin: 0 0 80px 0;
}

.noMargin {
    margin: 0;
}

.contactContent h2 {
    text-decoration: underline;
}

.contactCardStyle:hover .cardOverlay {
    width: 100%;
}

.sectorMarker {
    font-weight: 900;
}

.eventsCardStyle:hover .sectorMarker {
    color: black;
}

/*End events Card Styles*/


/*Colour Styles*/
.teal {
    color: #40E0D0;
}

.tealBg {
    background-color: #40E0D0;
}

.blue {
    color: #6495ED;
}

.blueBg {
    background-color: #6495ED;
}

.yellow {
    color: #FFDE00;
}

.yellowBg {
    background-color: #FFDE00;
}

.red {
    color: #FF6A62;
}

.redBg {
    background-color: #FF6A62;
}

.purple {
    color: #8240F0;
}

.purpleBg {
    background-color: #8240F0;
}

.cream {
    color: #F9F8EE;
}

.creamBg {
    background-color: #F9F8EE;
}

.ecoGreen {
    color: #A2C69E;
}

.ecoGreenBg {
    background-color: #A2C69E;
}

.ltGreyBg {
    background-color: #F7F7F7;
}

.midGrey {
    color: #9A9898;
}

.midGreyBg {
    background-color: #9A9898;
}

.ltTealBg {
    background-color: #8AE3DA;
}

.cameoGreenBg {
    background-color: #E0DCB6;
}

.pinkBg {
    background-color: #F3AAD1;
}

.orangeBg {
    background-color: #FFA462;
}

.ltBlueBg {
    background-color: #8DAEE8;
}

.whiteBg {
    background-color: #ffffff;
}
/*End Colour Styles*/

.normal {
    font-weight: 400;
}

.bold {
    font-weight: 500;
}

.medium {
    font-weight: 700;
}

.exBold {
    font-weight: 900;
}

.contactIconStyle {
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 15px 0;
}

/*Responsive styles*/

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 768px) {
    .contactContentContainer {
        grid-template-columns: 1fr !important;
        margin: 50px 0 50px 0;
    }

    .contactContentleft {
        padding: 0 !important;
    }

    .contactContentRight {
        border-left: none !important;
        padding: 0 !important;
    }

    .contactHeroImage {
        grid-column: 1/1 !important;
        height: 300px !important;
    }

    .contactPageContainer {
        padding-top: 80px !important;
    }

    .formContainer {
        border-top: 1px solid #CBCBCB !important;
        margin: 30px 0 0 0 !important;
        padding: 30px 0 0 0 !important;
    }


}

@media screen and (max-width: 425px) {

    .contactPageContainer {
        padding-top: 70px !important;
    }

    .contactHeroImage {
        height: 250px !important;
    }

    h1 {
        font-size: 10vw !important;
        line-height: 120%;
    }
}

@media screen and (max-width: 326px) {
  /*  .smallText {
        font-size: 5vw !important;
        line-height: 110%;
    }*/
}

@media screen and (max-width: 313px) {
}
