.carouselWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5em;
    align-items: center;
    padding: 50px 100px;
    border-radius: 15px;
    height: auto;
}

.bgColourBlue {
    background-color: #8daee8;
}

.bgColourEcoGreen {
    background-color: #A2C69E;
}

.carouselCaption {
    position: relative;
    color: #000;
    display: block;
    text-align: left;
    padding: 0 10% 0 0;
}

.carouselCaption h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0 0 20px 0;
}

.carouselCaption h2 {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 40px 0;
}

.marketingBannerImage {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 300px;
    width: auto;
    border-radius: 15px;
}

.video iframe {
    display: block;
    height: 300px;
    width: 100%;
    border-radius: 15px;
    border: 0;
    padding: 0;
    margin: 0;
}

.hide {
    display: none;
    visibility:hidden;
    overflow: hidden;
    line-height: 0;
    font-size:0;
}

/*.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -45px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: left;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 2%;
    list-style: none;
}

.carousel-indicators button {
    width: 100px;
    height: 0;
    background-color: rgba(255, 255, 255, 0);
    margin: 5px 2.5px;
    border-radius: 30px;
    border: solid 1px black;
    padding: 1.5px 6px;
    transition: all 0.4s ease-in-out;
}

.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 230px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 0;
    color: #000000;
    text-align: center;
    background: 0 0;
    background-color: #ffffff;
    border: 0;
    opacity: 1;
    filter: drop-shadow(1px 1px 1px grey);
    transition: opacity .15s ease;
}

.carousel-control-prev {
    left: -24px;
}

.carousel-control-next {
    right: -24px;
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    color: #000;
    text-decoration: none;
    outline: 0;
    opacity: 1;

 }

.carousel-control-next, .carousel-control-prev {*/
        /*padding-right: 0px;*/
        /*transition: all 0.2s ease-out;
}

.carousel-control-prev:hover {
        padding-right: 10px;
}

.carousel-control-next:hover {
        padding-left: 10px;
    }*/

@media screen and (max-width: 1200px) {
    .carouselWrapper {
        padding: 50px !important;
    }
}

@media screen and (max-width: 991px) {
    .carouselWrapper {
        grid-template-columns: 1fr !important;
        grid-gap: 2em !important;
        grid-gap: 0em !important;
    }

    .marketingBannerImage, .video iframe {
        /*height: 200px !important;*/
        margin: 30px 0 0 0 !important;
    }

    .carousel-caption {
        padding: 10%;
    }
  
}

@media screen and (max-width: 768px) {
    
}

@media screen and (max-width: 425px) {

    .carouselWrapper {
        padding: 30px !important;
    }

    .marketingBannerImage, .video iframe {
        height: 200px !important;
    }

    .carouselCaption h2 {
        font-size: 18px;
        line-height: 26px;
        margin: 0 0 20px 0;
    }

  /*  .carousel-control-prev {
        left: -10px !important;
    }

    .carousel-control-next {
        right: -10px !important;
    }

    .carousel-control-next, .carousel-control-prev {
        top: 160px !important;
    }*/
   
}
