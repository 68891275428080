/*.Purple, .Purple:focus{
  color: var(--GEH-Purple);
  background-color: transparent ;
  border-color: var(--GEH-Purple) ;
  border-radius: 50px;
  font-weight: bold;
}

.Purple:disabled {
  color: var(--GEH-Purple);
  background-color: grey;
  border-color: var(--GEH-Purple);
  font-weight: bold;
}

.Purple:hover {
  color: white !important;
  background-color: var(--GEH-Purple) !important;
  border: 1px solid var(--GEH-Purple) !important;
}*/


/*.Teal, .Teal:focus  {
  color: var(--teal-colour);
  background-color: transparent ;
  border-color: var(--teal-colour) ;
  border-radius: 50px;
  font-weight: bold;
}

.Teal:disabled {
  color: var(--teal-colour);
  background-color: grey;
  border-color: var(--teal-colour);
}

.Teal:hover{
  color: white;
  background-color: var(--teal-colour) !important;
  border: 1px solid var(--teal-colour) !important;
}*/

/*.LightGreen, .LightGreen:focus {
    color: var(--GEH-Green);
    background-color: transparent;
    border-color: var(--GEH-Green);
    border-radius: 50px;
    font-weight: bold;
}

    .LightGreen:disabled {
        color: var(--GEH-Green);
        background-color: grey;
        border-color: var(--GEH-Green);
    }

    .LightGreen:hover {
        color: white;
        background-color: var(--GEH-Green) !important;
        border: 1px solid var(--GEH-Green) !important;
    }

.Green, .Green:focus {
    color: var(--dark-green);
    background-color: transparent;
    border-color: var(--dark-green);
    border-radius: 50px;
    font-weight: bold;
}

.Green:disabled {
    color: var(--dark-green);
    background-color: grey;
    border-color: var(--dark-green);
}

    .Green:hover {
        color: white;
        background-color: var(--dark-green) !important;
        border: 1px solid var(--dark-green) !important;
    }*/


/*.White, .White:focus{
  color: white;
  background-color: transparent ;
  border-color: white ;
  border-radius: 50px;
  font-weight: bold;
}
*/
/*.White:disabled {
  color: black;
  background-color: grey;
  border-color: white;
  font-weight: bold;
}*/

/*.White:hover {
  color: black !important;
  background-color: white !important;
  border: 1px solid white !important;
}*/

/*.Grey, .Grey:focus{
  color: grey;
  background-color: transparent ;
  border-color: Grey ;
  border-radius: 50px;
  font-weight: bold;
}

.Grey:disabled {
  color: white;
  background-color: grey;
  border-color: Grey;
  font-weight: bold;
}

.Grey:hover {
  color: white !important;
  background-color: Grey !important;
  border: 1px solid grey !important;
}*/

.Black {
    display: inline;
    border: 3px solid #000000;
    border-radius: 15px;
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    padding: 18px 25px 21px 25px;
    width: auto;
    font-weight: 500;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    transition-property: all 0.3s;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

 .Black:hover {
        border: 3px solid #000000;
        background: none;
        color: #000000;
    }

.Teal {
    display: inline;
    border: 3px solid rgba(64, 224, 208, 1);
    background-color: rgba(64, 224, 208, 1);
    border-radius: 15px;
    color: #000000;
    text-align: center;
    cursor: pointer;
    padding: 18px 25px 21px 25px;
    width: auto;
    font-weight: 500;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    transition-property: all 0.3s;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

.Teal:hover {
    border: 3px solid rgba(64, 224, 208, 0.5);
    background-color: rgba(64, 224, 208, 0.5);
 }

.White {
    display: inline;
    border: 2px solid #ffffff;
    background: none;
    border-radius: 15px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    padding: 18px 25px 21px 25px;
    width: auto;
    font-weight: 500;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    transition-property: all 0.3s;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

.White:hover {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #000000;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 425px) {
    .White, .Black, .Teal {
        width: 100% !important;
    }
   
}

