
.reviewContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5em;
    align-items: start;
    position: relative;
    max-width: 1440px;
    padding: 100px 5% 50px 5%;
    margin: auto;
}

.reviewContainer h1 {
    font-weight: 900;
}

.reviewContainer h1,
    h2,
    h3,
    p,
    ul {
        margin: 0 0 30px 0;
}

.reviewContainer iframe {
    border-radius: 15px;
}

/*Responsive Styles*/

@media screen and (max-width: 991px) {

    .reviewContainer {
        grid-template-columns: 1fr !important;
        grid-gap: 2em !important;
        margin: 0px auto !important;
        padding-top: 80px !important;
    }
}

