.newsPageContainer {
    display: flex;
    flex-direction: column;
    padding: 50px 0 0 0;
}
.newsPageContainer h1{
    font-size:35px;
    margin:0 0 15px 0;
}

.newsSection {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 3em;
    align-items: initial;
    margin: 50px auto 20px auto;
    padding: 0% 5% 0% 5%;
}

.newsSectionTitle {
    /*width: 45%;*/
    border-left: 1px solid #000000;
    padding: 0 0 0 50px;
}

/*News Card Styles*/

/*Sector Styles*/
.sectorMarker {
    font-size: 14px;
    font-weight: 900;
    transition: all 0.2s ease-in-out;
}

.sectorName {
    font-size: 14px;
    text-decoration: underline;
}

.date {
    font-weight: 500;
}

.sectorContainer {
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 0 50px 0;
    background-color: #F7F7F7;
}


.newsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 3em;
    align-items: initial;
}

.newsContainer {
    position: relative;
    max-width: 1440px;
    padding: 0% 5% 0% 5%;
    margin: 0 auto;
}

.newsIdxContainer {
    display: grid;
    grid-template-rows: auto;
}

.newsIdxContainer div {
    display: grid;
    justify-content:start;
}

.firstNewsItem {
    grid-column: 1/3;
}

.firstNewsItem .newsCardImage {
    height: 350px;
}

.newsCardImage {
    border-radius: 15px 15px 0 0;
    height: 200px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 140%;
    transition: all 0.2s ease-in-out;
}

.newsCardStyle {
    border-radius: 15px;
    z-index: 0;
}

.cardOverlayContainer {
    position: relative;
    width: auto;
    z-index: 1;
}

.cardOverlay {
    border-radius: 0 0 0 15px;
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 100%;
    transition: all 0.2s ease-in-out;
}

.newsContent {
    display: grid;
    align-items: center;
    position: relative;
    padding: 40px;
    height: 375px;
    z-index: 2;
}

.newsContentMargin {
    margin: 0 0 15px 0;
}

.newsContent h3 {
    text-decoration: underline;
}

.newsCardStyle:hover .cardOverlay {
    width: 100%;
    border-radius: 0 0 15px 15px;
}

.newsCardStyle:hover .newsCardImage {
    background-size: 150%;
}

.newsCardStyle:hover .sectorMarker {
    color: black;
}
/*filterby should be global*/
.pointer {
    cursor: pointer;
}

.offCanvas {
    z-index: 10000 !important;
    width: 50% !important;
}

/*Responsive styles*/

@media screen and (max-width: 991px) {
    .newsSectionTitle {
        width: 100% !important;
    }

    .newsContainer {
        grid-template-columns: repeat(2, 1fr) !important;
        grid-gap: 2em !important;
    }

    .newsSection {
        grid-template-columns: 1fr !important;
        grid-gap: 0 !important;
    }

    .newsSectionTitle {
        border-left: none !important;
        padding: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .newsSectionTitle h1 {
        font-size: 32px !important;
        line-height: 40px !important;
    }
    .newsContainer {
        grid-template-columns: 1fr !important;
    }

    .firstNewsItem {
        grid-column: 1/1 !important;
    }
}

@media screen and (max-width: 425px) {

    h1 {
        font-size: 10vw !important;
        line-height: 120%;
    }
   
}

@media screen and (max-width: 326px) {
   
}

@media screen and (max-width: 313px) {

    .newsPageContainer {
        padding: 80px 0 0 0 !important;
    }
}

