
.sectionContainer1 {
    /*position: absolute;*/
    width: 100%;
    height: auto;
}

.sectionContainer2, .sectionContainer3, .sectionContainer4, .sectionContainer5, .sectionContainer6, .sectionContainer7, .sectionContainer8, .sectionContainer9, .sectionContainer10 {
    position: relative;
    width: 100%;
    height: auto;
}

.sectionContainer1 {
    top: 0px;
    padding: 0px 0 50px 0;
}

.sectionContainer2, .sectionContainer3, .sectionContainer4, .sectionContainer5, .sectionContainer6, .sectionContainer7, .sectionContainer8, .sectionContainer9, .sectionContainer10 {
    padding: 50px 0 50px 0;
}

.Section1, .Section2, .Section3, .Section4, .Section5, .Section6, .Section7, .Section8, .Section9, .Section10 {
    position: relative;
    max-width: 1440px;
    padding: 0% 5% 0% 5%;
    margin: 50px auto;
}

.Section1, .Section2, .Section3, .Section4, .Section5, .Section6, .Section7, .Section8, .Section9, .Section10 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5em;
    align-items: initial;
}

.Section1 {
    z-index: 4;
    /*margin-bottom: -115px*/
}

.Section2 {
    z-index: 3;
}

.Section3 {
    z-index: 2;
}

.Section4 {
    z-index: 1;
}

.image1, .image2, .image3, .image4, .image5, .image6, .image7, .image8, .image9, .image10 {
    position: absolute;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.image1, .image2 {
    height: calc(100% + 160px);
}


/*.image1 {*/
/*height: 700px;*/
/*transform: translate(0, 2rem);*/
/*position:absolute;
    width:40%;
    left:50vw;*/
/*}

.image2 {
    height: 500px;
}

.image3 {
    height: 450px;
}

.image4, .image5, .image6, .image7, .image8, .image9, .image10 {
    height: 400px;
}*/
@media screen and (max-width: 3000px) {
    .Section1 {
        /*margin-bottom: -80px;*/
    }
}

@media screen and (max-width: 1920px) {
    .Section1 {
        /*margin-bottom: -120px;*/
    }
}

@media screen and (max-width: 1360px) {
    .Section1 {
        /*margin-bottom: -80px;*/
    }
}

@media screen and (max-width: 1284px) {
    .Section1 {
        /*margin-bottom: 50px;*/
    }
}

@media screen and (max-width: 768px) {

    .Section1, .Section2, .Section3, .Section4, .Section5, .Section6, .Section7, .Section8, .Section9, .Section10 {
        grid-template-columns: 1fr !important;
        grid-gap: 2em !important;
    }

    .Section1, .Section2, .Section3, .Section4, .Section5, .Section6, .Section7, .Section8, .Section9, .Section10 {
        margin: 0px auto !important;
    }

    .image1, .image2, .image3, .image4, .image5, .image6, .image7, .image8, .image9, .image10 {
        position: relative;
        /*display: grid;*/
        height: 400px;
    }

   /* .image1 {
        position: relative;
        width: 100%;
        left: 0;
    }

    .image1, .image2, .image3 {
        height: 400px !important;
    }*/

    .image2, .image4 {
        grid-row: 2/3 !important;
    }
}

@media screen and (max-width: 425px) {

    .image1, .image2, .image3, .image4, .image5, .image6, .image7, .image8, .image9, .image10 {
        height: 250px !important;
    }

    h1 {
        font-size: 10vw !important;
        line-height: 120%;
    }
}
