.pageContainer {
    position: relative;
    /*min-height: 2275px;*/
}

.pageContainer h1 {
    font-weight: 900;
    padding: 30px 0 0 0;
}

.pageContainer h1,
    h2,
    h3,
    p,
    ul {
        margin: 0 0 30px 0;
}

.pageContainer a {
    flex-basis: auto;
}

.whatWeDoSectionContainer1, .whatWeDoSectionContainer2, .whatWeDoSectionContainer3, .whatWeDoSectionContainer4 {
    position: relative;
    width: 100%;
    height: auto;
}

.whatWeDoSectionContainer5 {
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 0 50px 0;
}

    .whatWeDoSectionContainer5 h1,
    h2,
    h3,
    p {
        margin: 0 0 30px 0;
    }

    .whatWeDoSectionContainer5 h1 {
        font-weight: 900;
        margin: 0 0 30px 0;
    }

.whatWeDoSectionContainer1 {
    top: 0px;
    padding: 0px 0 50px 0;
}

.whatWeDoSectionContainer2 {
    /*top: 600px;*/
    padding: 50px 0 50px 0;
}

.whatWeDoSectionContainer3 {
    /*top: 1160px;*/
    padding: 50px 0 50px 0;
}

.whatWeDoSectionContainer4 {
    /*top: 1680px;*/
    padding: 50px 0 50px 0;
}

.whatWeDo_Section1, .whatWeDo_Section2, .whatWeDo_Section3, .whatWeDo_Section4, .workWithUs_Section5 {
    position: relative;
    max-width: 1440px;
    padding: 0% 5% 0% 5%;
    margin: 50px auto;
}

.whatWeDo_Section1, .whatWeDo_Section2, .whatWeDo_Section3, .whatWeDo_Section4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5em;
    align-items: start;
}

.whatWeDo_Section1 {
    z-index: 4;
}

.whatWeDo_Section2 {
    z-index: 3;
}

.whatWeDo_Section3 {
    z-index: 2;
}

.whatWeDo_Section4 {
    z-index: 1;
}

.image1, .image2, .image3, .image4, .image5 {
    border-radius: 15px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/*.image1 {
    height: 600px;
}

.image2 {
    height: 500px;
}

.image3 {
    height: 450px;
}

.image4 {
    height: 400px;
}

.image5 {
    height: 400px;
}*/

/*End Who we are page styles*/

/*Colour Styles*/
.teal {
    color: #40E0D0;
}

.tealBg {
    background-color: #40E0D0;
}

.blue {
    color: #6495ED;
}

.blueBg {
    background-color: #6495ED;
}

.yellow {
    color: #FFDE00;
}

.yellowBg {
    background-color: #FFDE00;
}

.ltYellowBg {
    background-color: #FFF29A;
}

.yellowBg_10 {
    background-color: rgba(255, 222, 0, 0.1); /*Opacity @ 10%*/
}

.red {
    color: #FF6A62;
}

.redBg {
    background-color: #FF6A62;
}

.purple {
    color: #8240F0;
}

.purpleBg {
    background-color: #8240F0;
}

.ltPurpleBg {
    background-color: #CDB2F9;
}

.cream {
    color: #F9F8EE;
}

.creamBg {
    background-color: #F9F8EE;
}

.ecoGreen {
    color: #A2C69E;
}

.ecoGreenBg {
    background-color: #A2C69E;
}

.ltGreyBg {
    background-color: #F7F7F7;
}

.midGrey {
    color: #9A9898;
}

.midGreyBg {
    background-color: #9A9898;
}

.ltTealBg {
    background-color: #8AE3DA;
}

.cameoGreenBg {
    background-color: #E0DCB6;
}

.ltCameoGreenBg {
    background-color: #C7DDC5;
}

.pinkBg {
    background-color: #F3AAD1;
}

.orangeBg {
    background-color: #FFA462;
}

.ltBlueBg {
    background-color: #8DAEE8;
}

.ltRedBg {
    background-color: #FFC4C1;
}

.whiteBg {
    background-color: #ffffff;
}
/*End Colour Styles*/

/*Responsive Styles*/

@media screen and (max-width: 3840px) {
.sectionContainer2 {
    /*top: 630px !important;*/
}
}

@media screen and (max-width: 1242px) {

.pageContainer {
    /*min-height: 2352px !important;*/
}

.sectionContainer2 {
    /*top: 692px !important;*/
}

.sectionContainer3 {
    /*top: 1265px !important;*/
}

.sectionContainer4 {
   /* top: 1788px !important;*/
}
}

@media screen and (max-width: 1144px) {

    .pageContainer {
        /*min-height: 2542px !important;*/
    }

    .sectionContainer2 {
        /*top: 692px !important;*/
    }

    .sectionContainer3 {
        /*top: 1329px !important;*/
    }

    .sectionContainer4 {
        /*top: 1929px !important;*/
    }
}

@media screen and (max-width: 1018px) {

    .pageContainer {
        /*min-height: 2708px !important;*/
    }

    .sectionContainer2 {
        /*top: 751px !important;*/
    }

    .sectionContainer3 {
        /*top: 1409px !important;*/
    }

    .sectionContainer4 {
        /*top: 2038px !important;*/
    }
}

@media screen and (max-width: 991px) {
    .pageContainer {
        /*min-height: 2862px !important;*/
    }

    .image1 {
        height: 840px !important;
    }

    .sectionContainer2 {
        /*top: 820px !important;*/
    }

    .sectionContainer3 {
       /* top: 1476px !important;*/
    }

    .sectionContainer4 {
        /*top: 2160px !important;*/
    }
}

@media screen and (max-width: 840px) {
    .pageContainer {
        /*min-height: 3185px !important;*/
    }

    .sectionContainer2 {
        /*top: 920px !important*/
    }

    .sectionContainer3 {
        /*top: 1671px !important;*/
    }

    .sectionContainer4 {
        /*top: 2426px !important;*/
    }
}

@media screen and (max-width: 768px) {

.pageContainer {
    /*min-height: auto !important;*/
}

.whatWeDo_Section1, .whatWeDo_Section2, .whatWeDo_Section3, .whatWeDo_Section4, .workWithUs_Section5 {
    grid-template-columns: 1fr !important;
    grid-gap: 2em !important;
    margin: 0px auto !important;
}

.image1, .image2, .image3 {
    height: 400px !important;
}

.whatWeDoSectionContainer1, .whatWeDoSectionContainer2, .whatWeDoSectionContainer3, .whatWeDoSectionContainer4 {
        position: relative !important;
        /*top: auto !important;*/
    }

.image2, .image4 {
    grid-row: 2/3 !important;
}

}

@media screen and (max-width: 568px) {
    .pageContainer a {
        flex-grow: 1;
    }
}

@media screen and (max-width: 425px) {
    .pageContainer {
        /*min-height: 3800px !important;*/
    }

    .image1, .image2, .image3, .image4, .image5 {
        height: 250px !important;
    }

    h1 {
        font-size: 10vw !important;
        line-height: 120%;
    }
}

@media screen and (max-width: 326px) {
    .pageContainer {
        /*min-height: 4072px !important;*/
    }
}
