.container {
    padding: 80px 40px;
    background-color: #F7F7F7;
    border-radius: 15px;
}

.container div {
    text-align: left;
}

.link {
    display: block;
    cursor: pointer;
    color: #9A9898;
    margin: 20px 0 0 0;
    text-decoration: underline;
    transition: all .25s ease-in-out;
}

.link:hover {
    color: #000000;
}