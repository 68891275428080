.carouselWrapper {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5em;
    align-items: center;
}

.carousel-item {
    background-color: #ffffff;
    height: 350px; 
    border-radius: 15px;
}


.carousel-caption {
    position: relative;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    color: #000;
    display: block;
    text-align: left;
    padding: 0 10% 0 0;
}

.carouselImageContainer {
    background-repeat: no-repeat; 
    background-position: center;
    background-size: cover;
    height: 350px;
    border-radius: 15px 0 0 15px;
}

.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 130px;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 0;
    color: #000000;
    text-align: center;
    background: 0 0;
    background-color: #ffffff;
    border: 0;
    opacity: 1;
    filter: drop-shadow(1px 1px 1px grey);
    transition: opacity .15s ease;
}

.carousel-control-prev {
    left: -24px;
}

.carousel-control-next {
    right: -24px;
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    color: #000;
    text-decoration: none;
    outline: 0;
    opacity: 1;

 }

.carousel-control-next, .carousel-control-prev {
        /*padding-right: 0px;*/
        transition: all 0.2s ease-out;
}

.carousel-control-prev:hover {
        padding-right: 10px;
}

.carousel-control-next:hover {
        padding-left: 10px;
    }

@media screen and (max-width: 991px) {
    .carouselWrapper {
        grid-template-columns: 1fr !important;
        grid-gap: 2em !important;
        grid-gap: 0em !important;
    }

    .carousel-item {
        height: auto !important;
    }

    .carouselImageContainer {
        height: 200px !important;
        border-radius: 15px 15px 0 0 !important;
    }

    .carousel-caption {
        padding: 10%;
    }
  
}

@media screen and (max-width: 768px) {
    
}

@media screen and (max-width: 425px) {
    .carousel-control-prev {
        left: -10px !important;
    }

    .carousel-control-next {
        right: -10px !important;
    }

    .carousel-control-next, .carousel-control-prev {
        top: 160px !important;
    }
   
}
