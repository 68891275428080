.accessContainer {
    padding: 5%;
    display: flex;
    flex-direction: column;
}

.accessTitle {
    font-weight: bold;
}

.accessContainer p {
    margin-bottom: 1%;
}

.accessContainer .link {
    color: var(--marketing-blue)
}

    .accessContainer .link:hover {
        color: var(--main-font-colour)
    }