.privacyContainer {
    padding: 5%;
    display: flex;
    flex-direction: column;
}

.privacyTitle {
    font-weight: bold;
}

.privacyContainer p {
    margin-bottom: 1%;
}

.privacyContainer .link {
    color: var(--marketing-blue)
}

    .privacyContainer .link:hover {
        color: var(--main-font-colour)
    }

.privacyContainer ul {
   /* list-style-type: none;*/
/*    padding-left: 0;*/
    margin-top: 3px;
    padding-top: 6px;
    margin-bottom: 15px;
}

.privacyContainer ul li {
    line-height: 1.5rem;
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
    padding-top: 1px;
}

.privacyContainer ul li:before {
    position: absolute;
    top: .5px;
    left: 0;
    font-size: 10px;
    color: var(--green-colour);
}