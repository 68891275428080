
.teal {
    color: #40E0D0;
}
.tealBg {
    background-color: #40E0D0;
}
.blue {
    color: #6495ED;
}
.blueBg {
    background-color: #6495ED;
}
.yellow {
    color: #FFDE00;
}
.yellowBg {
    background-color: #FFDE00;
}
.red {
    color: #FF6A62;
}
.redBg {
    background-color: #FF6A62;
}
.purple {
    color: #8240F0;
}
.purpleBg {
    background-color: #8240F0;
}
.ltPurple {
    color: #9F71EC;
}
.ltPurpleBg {
    background-color: #9F71EC;
}
.cream {
    color: #F9F8EE;
}
.creamBg {
    background-color: #F9F8EE;
}
.ecoGreen {
    color: #A2C69E;
}
.ecoGreenBg {
    background-color: #A2C69E;
}
.ltGreyBg {
    background-color: #F7F7F7;
}
.midGrey {
    color: #9A9898;
}
.midGreyBg {
    background-color: #9A9898;
}
.ltTealBg {
    background-color: #8AE3DA;
}
.cameoGreenBg {
    background-color: #E0DCB6;
}
.pink {
    color: #F3AAD1;
}
.pinkBg {
    background-color: #F3AAD1;
}
.orange {
    color: #FFA462;
}
.orangeBg {
    background-color: #FFA462;
}
.ltBlueBg {
    background-color: #8DAEE8;
}
.whiteBg {
    background-color: #ffffff;
}
