/*.eventsPageContainer {
    display: flex;
    flex-direction: column;
    max-width: 1020px;
    padding: 0% 5% 0 5%;
    margin: 0 auto;
}*/
.newsContainer {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 0% 5% 0 5%;
    margin: 0 auto;
    padding-top: 100px;
}
.linkContainer{
    /*padding:20px;*/
}
.innerContainer{
    margin-top:25px;
}
.newsContainerTop {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(0px, auto);
    grid-column-gap: 1em;
    align-items: end;
}
.newsContentMargin {
    margin: 0 0 15px 0 !important;
}

.newsContainerTop div:last-child {
    display: grid;
    grid-column: 1/3;
}

.newsHeroImage {
    border-radius: 15px;
    width: 100%;
    height: 400px;
    grid-column: 1/3;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.newsItemContentContainer {
    display: grid;
    grid-template-columns: 6fr 2fr;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 0em;
    align-items: start;
    margin: 50px 0 50px 0;
}
.newsItemContentleft {
    border-right: 1px solid #000000;
    padding: 0 50px 0 0;
}

.newsItemContentleft h1 {
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
    margin: 0 0 30px 0;
}

.newsItemContentleft h2 {
    font-size: 18px;
    font-weight: 900;
    line-height: 24px;
}

.newsItemContentleft h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}


.newsItemContentleft img {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

    /*.newsItemContentleft {
    border-right: 1px solid #000000;
    padding: 0 50px 0 0;
}*/

.newsDetailsContainer {
    border-bottom: 1px solid #CBCBCB;
    margin: 0 0 30px 0;
    padding: 0 0 20px 0;
}

.newsDetailsContainer p {
    margin: 0;
}

.newsContentContainer span p a {
    font-weight: bold;
    color: #40E0D0;
    text-decoration: underline;
    overflow-wrap: anywhere;
}


/*Aside styles*/

.newsAsideContainer {

}

.socialContainer {
    padding: 0 0 0 40px;
}

.newsCardStyle {
    z-index: 0;
    border-bottom: 1px solid #CBCBCB;
}

.cardOverlayContainer {
    position: relative;
    width: auto;
    z-index: 1;
}

.cardOverlay {
    border-radius: 0 0 0 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 100%;
    transition: all 0.2s ease-in-out;
}
.newsContent {
    position: relative;
    padding: 40px;
    height: auto;
    z-index: 2;
}
.newsContent h2 {
    text-decoration: underline;
}

.newsCardStyle:hover .cardOverlay {
    width: 100%;
}

/*Sector Styles*/
.sectorMarker {
    font-size: 14px;
    font-weight: 900;
    transition: all 0.2s ease-in-out;
}

.sectorName {
    font-size: 14px;
    text-decoration: underline;
}

/*.sectorMarker {
    font-weight: 900;
}*/

.newsCardStyle:hover .sectorMarker {
    color: black;
}
.newsContentExMargin {
    margin: 0 0 80px 0 !important;
}
.scollMarker {
    cursor: pointer;
    color: #40E0D0;
    font-weight: bold;
    float: right;
}

/*Colour Styles*/
.teal {
    color: #40E0D0;
}

.tealBg {
    background-color: #40E0D0;
}

.blue {
    color: #6495ED;
}

.blueBg {
    background-color: #6495ED;
}

.yellow {
    color: #FFDE00;
}

.yellowBg {
    background-color: #FFDE00;
}

.red {
    color: #FF6A62;
}

.redBg {
    background-color: #FF6A62;
}

.purple {
    color: #8240F0;
}

.purpleBg {
    background-color: #8240F0;
}

.cream {
    color: #F9F8EE;
}

.creamBg {
    background-color: #F9F8EE;
}

.ecoGreen {
    color: #A2C69E;
}

.ecoGreenBg {
    background-color: #A2C69E;
}

.ltGreyBg {
    background-color: #F7F7F7;
}

.midGrey {
    color: #9A9898;
}

.midGreyBg {
    background-color: #9A9898;
}

.ltTealBg {
    background-color: #8AE3DA;
}

.cameoGreenBg {
    background-color: #E0DCB6;
}

.pinkBg {
    background-color: #F3AAD1;
}

.orangeBg {
    background-color: #FFA462;
}

.ltBlueBg {
    background-color: #8DAEE8;
}

.whiteBg {
    background-color: #ffffff;
}
/*End Colour Styles*/

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 768px) {
    .newsItemContentContainer {
        grid-template-columns: 1fr !important;
        grid-gap: 2em;
    }

    .newsItemContentleft {
        border-right: none !important;
        padding: 0 !important;
    }

    .newsHeroImage {
        height: 300px !important;
    }

    .socialContainer {
        padding: 0;
    }

}

@media screen and (max-width: 425px) {

    .newsHeroImage {
        height: 250px !important;
    }

    h1 {
        font-size: 10vw !important;
        line-height: 120%;
    }

    .newsPageContainer {
        padding-top: 80px !important;
    }

    .newsItemContentContainer {
        margin-top: 20px !important;
    }

    .newsContentExMargin {
        margin: 0 0 30px 0 !important;
    }
}

@media screen and (max-width: 326px) {
}

@media screen and (max-width: 313px) {
}