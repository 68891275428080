.pageContainer {
    position: relative;
}

.pageContainer h1 {
    font-weight: 900;
    padding: 30px 0 0 0;
}

.pageContainer h1,
h2,
h3,
p,
ul {
    margin: 0 0 30px 0;
}

.pageContainer a {
    flex-basis: auto;
}

.workWithUsContainer {
    display: flex;
    flex-direction: column;
    padding: 2% 0 2% 0;
}


.workWithUsSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5em;
    align-items: center;
}

.image1 {
    border-radius: 15px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.image1 {
    height: 400px;
}

.container {
    border-radius: 15px;
    padding: 100px;
    background-color: #F9F8EE;
}

.contentContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5em;
    align-items: start;
}

.liMargin {
    margin: 0 0 10px 0;
}

.h2_50_style {
    display: grid;
    grid-template-columns: 50%;
    font-weight: 400;
    margin: 0 0 50px 0;
}

.h2_80_style {
    display: grid;
    grid-template-columns: 70%;
    font-weight: 400;
    margin: 0 0 50px 0;
}

.listStyle {
    color: #8240F0;
}

/*Tourism section styles*/
.sectorContainer {
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 0 50px 0;
}

.sectorContainer h1,
    h2,
    h3,
    p {
        margin: 0 0 30px 0;
}

.sectorContainer h1 {
    font-weight: 900;
}

.sectorContent {
        position: relative;
        max-width: 1440px;
        padding: 0% 5% 0% 5%;
        margin: 0 auto;
    }


.sectorContent {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5em;
    align-items: center;
}

.packageContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 1em;
    align-items: center;
}

.packageContainer {
    position: relative;
    max-width: 1440px;
    padding: 0% 5% 0% 5%;
    margin: 0px auto;
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    filter: opacity(0);
    transition: all .25s ease-in-out;
}

.packageContainer.active {
    opacity: 1;
    filter: opacity(100);
    height: auto;
    margin-top: 50px;
    max-height: 1620px;
}

.packageCard {
    border-radius: 15px;
    width: 100%;
    height: auto;
    padding: 40px;
}

.tableContainer {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: minmax(0px, auto);
    grid-gap: 1px;
    align-items: center;
}

.tableContainer div {
    padding: 10px;
}

.icon {
    display: inline-block;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.icon.rotate {
    transform: rotate(90deg);
}

.pointer {
    cursor: pointer;
}
/*End Tourism section styles*/
.yellowBg {
    background-color: rgba(255, 222, 0, 1); /*Opacity @ 100%*/
}

.yellowBg_10 {
    background-color: rgba(255, 222, 0, 0.1); /*Opacity @ 10%*/
}

.yellowBg_60 {
    background-color: rgba(255, 222, 0, 0.6); /*Opacity @ 60%*/
}

.ecoGreenBg {
    background-color: rgba(162, 198, 158, 1); /*Opacity @ 100%*/
}

.ecoGreenBg_10 {
    background-color: rgba(162, 198, 158, 0.1); /*Opacity @ 10%*/
}

.ecoGreenBg_60 {
    background-color: rgba(162, 198, 158, 0.6); /*Opacity @ 60%*/
}

/*Colour Styles*/
.teal {
    color: #40E0D0;
}

.tealBg {
    background-color: #40E0D0;
}

.blue {
    color: #6495ED;
}

.blueBg {
    background-color: #6495ED;
}

.yellow {
    color: #FFDE00;
}

.yellowBg {
    background-color: #FFDE00;
}

.ltYellowBg {
    background-color: #FFF29A;
}

.yellowBg_10 {
    background-color: rgba(255, 222, 0, 0.1); /*Opacity @ 10%*/
}

.red {
    color: #FF6A62;
}

.redBg {
    background-color: #FF6A62;
}

.purple {
    color: #8240F0;
}

.purpleBg {
    background-color: #8240F0;
}

.ltPurpleBg {
    background-color: #CDB2F9;
}

.cream {
    color: #F9F8EE;
}

.creamBg {
    background-color: #F9F8EE;
}

.ecoGreen {
    color: #A2C69E;
}

.ecoGreenBg {
    background-color: #A2C69E;
}

.ltGreyBg {
    background-color: #F7F7F7;
}

.midGrey {
    color: #9A9898;
}

.midGreyBg {
    background-color: #9A9898;
}

.ltTealBg {
    background-color: #8AE3DA;
}

.cameoGreenBg {
    background-color: #E0DCB6;
}

.ltCameoGreenBg {
    background-color: #C7DDC5;
}

.pinkBg {
    background-color: #F3AAD1;
}

.orangeBg {
    background-color: #FFA462;
}

.ltBlueBg {
    background-color: #8DAEE8;
}

.ltRedBg {
    background-color: #FFC4C1;
}

.whiteBg {
    background-color: #ffffff;
}
/*End Colour Styles*/

.medium {
    font-weight: 700;
}

.bold {
    font-weight: 500;
}

.noMargin {
    margin: 0;
}

.noMarginCenter {
    margin: 0 auto;
}

.marginTop {
    margin: 30px 0 0 0;
}

.center {
    text-align: center;
}

li::marker {
    color: #8240F0 !important;
}

@media screen and (max-width: 1255px) {

    .packageContainer {
        grid-template-columns: 1fr !important;
        grid-gap: 1em !important;
    }
}

@media screen and (max-width: 991px) {


}

@media screen and (max-width: 768px) {
    .workWithUsSection {
        grid-template-columns: 1fr !important;
        grid-gap: 2em !important;
    }

    section {
        padding: 5% !important;
        margin: 0 auto !important;
    }

    .sectorContainer {
        padding-bottom: 20px !important;
    }

    .sectorContent {
        grid-template-columns: 1fr !important;
        grid-gap: 1em !important;
    }

    .image1 {
        grid-row: 1/2 !important;
        margin: 0 0 30px 0 !important;
    }

    .container {
        padding: 7% !important;
    }

    .h2_50_style {
        grid-template-columns: 100% !important;
        margin: 0 0 30px 0 !important;
    }

    .contentContainer {
        grid-template-columns: 1fr !important;
        grid-gap: 0em !important;
        margin: 0 0 30px 0 !important;
    }

     .contentContainer ul {
         margin: 0 !important;
     }
   
}

@media screen and (max-width: 660px) {
    .tableContainer {
        grid-template-columns: repeat(3, 1fr) !important;
    }

    .col4, .col5, .col6 {
        grid-row: 3/3 !important;
    }
}

@media screen and (max-width: 568px) {
    .pageContainer a {
        flex-grow: 1;
    }
}

@media screen and (max-width: 425px) {

    .tableContainer {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .col3, .col4 {
        grid-row: 3/3 !important;
    }

    .col5, .col6 {
        grid-row: 5/5 !important;
    }

    .image1 {
        height: 250px;
    }

    .packageCard {
        padding: 30px !important;
    }

    h1 {
        font-size: 10vw !important;
        line-height: 120% !important;
    }
   
}