.eventsPageContainer {
    display: flex;
    flex-direction: column;
    padding: 50px 0 0 0;
}

.listWrapper {
    width: 45%;
}

.sectorListContainer {
    border-top: solid 1px black;
    width: 50%;
    padding: 30px 0 0 0;
    transition: all .25s ease-in-out;
}

.sectorListContainer.grow {
    width: 100%;
}

.sectorList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0em;
    column-gap: 4em;
    align-items: start;
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    filter: opacity(0);
    transition: all .25s ease-in-out;
}

.sectorList.active {
    opacity: 1;
    filter: opacity(100);
    height: auto;
    max-height: 805px;
}

.eventsSectionTitle {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0em;
    column-gap: 4em;
    align-items: start;
}

/*events Card Styles*/

.sectorContainer {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0px 0 50px 0;
}

.sectorList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.sectorListItemContainer {
    display: grid;
    align-content: center;
    position: relative;
    height: 50px;
    z-index: 0;
}

.sectorListOverlay {
    position: absolute;
    left: 0px;
    height: 0px;
    width: 6px;
    height: 100%;
    transition: all 0.2s ease-in-out;
}

li:hover .sectorListOverlay {
    width: 100%;
}

.sectionListItem {
    position: relative;
    margin: 0 0 0 20px;
    z-index: 1;
}

.eventsContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 3em;
    align-items: center;
}

.eventsContainer {
    position: relative;
    max-width: 1440px;
    padding: 0% 5% 0% 5%;
    margin: 0 auto;
}

.eventsCardImage {
    border-radius: 15px 0 0 15px;
    height: 100%;
    width: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.eventsCardStyle:hover .eventsCardImage {
    opacity: 0.9;
    /*background-size: 220%;*/
}

.eventsCardStyle {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 0em;
    align-items: center;
}

.eventsCardStyle {
    border-radius: 15px;
    z-index: 0;
}

.cardOverlayContainer {
    position: relative;
    width: auto;
    z-index: 1;
}

.cardOverlay {
    border-radius: 0 0 0 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 100%;
    transition: all 0.2s ease-in-out;
}

.eventsContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(100px, auto);
    align-items: center;
    position: relative;
    padding: 40px;
    height: auto;
    z-index: 2;
}
.eventsContent p{
    margin:0;
}
.eventsContentMargin {
    margin: 0 0 15px 0 !important;
}

.noMargin {
    margin: 0;
}

.eventsContent h2 {
    text-decoration: underline;
}

.eventHeaderStyle {
    display: inline-block;
    font-size: 24px;
    line-height: 32px;
    font-weight: 900;
    text-decoration: underline;
}

.eventsCardStyle:hover .cardOverlay {
    width: 100%;
    border-radius: 0 15px 15px 0;
}

.sectorMarker {
    font-weight: 900;
}

.eventsCardStyle:hover .sectorMarker {
    color: black;
}

/*End events Card Styles*/

.icon {
    display: inline-block;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.icon.rotate {
    transform: rotate(90deg);
}

.pointer {
    cursor: pointer;
}

/*Colour Styles*/
.teal {
    color: #40E0D0;
}

.tealBg {
    background-color: #40E0D0;
}

.blue {
    color: #6495ED;
}

.blueBg {
    background-color: #6495ED;
}

.yellow {
    color: #FFDE00;
}

.yellowBg {
    background-color: #FFDE00;
}

.red {
    color: #FF6A62;
}

.redBg {
    background-color: #FF6A62;
}

.purple {
    color: #8240F0;
}

.purpleBg {
    background-color: #8240F0;
}

.cream {
    color: #F9F8EE;
}

.creamBg {
    background-color: #F9F8EE;
}

.ecoGreen {
    color: #A2C69E;
}

.ecoGreenBg {
    background-color: #A2C69E;
}

.ltGreyBg {
    background-color: #F7F7F7;
}

.midGrey {
    color: #9A9898;
}

.midGreyBg {
    background-color: #9A9898;
}

.ltTealBg {
    background-color: #8AE3DA;
}

.cameoGreenBg {
    background-color: #E0DCB6;
}

.pinkBg {
    background-color: #F3AAD1;
}

.orangeBg {
    background-color: #FFA462;
}

.ltBlueBg {
    background-color: #8DAEE8;
}

.whiteBg {
    background-color: #ffffff;
}
/*End Colour Styles*/

.normal {
    font-weight: 400;
}

.medium {
    font-weight: 700;
}

.bold {
    font-weight: 500;
}

/*Responsive styles*/

@media screen and (max-width: 991px) {

.listWrapper {
    width: 100% !important;
}

.sectorContainer {
     padding-top: 30px !important;
}

.eventsContent {
    grid-template-columns: 3fr 1fr !important;
    grid-gap: 2em !important;
}

.eventsCardImage {
    background-size: cover !important;
}

}

@media screen and (max-width: 768px) {
    .sectorList {
        grid-template-columns: repeat(2, 1fr) !important;
        column-gap: 2em !important;
    }

    .sectorListItemContainer {
        height: 70px !important;
    }

    .eventsSectionTitle {
        grid-template-columns: 1fr !important;
    }

    .eventsContent {
        grid-template-columns: 3fr 1fr !important;
        grid-gap: 0.5em !important;
    }

    .eventsSectionTitle h1 {
        font-size: 32px !important;
        line-height: 40px !important;
    }

    .eventsContent {
        grid-template-columns: 1fr !important;
    }

    .eventsButton {
        text-align: left !important;
    }

 
}

@media screen and (max-width: 425px) {

    .eventsCardStyle {
        grid-template-columns: 1fr !important;
    }

    .eventsCardImage {
        border-radius: 15px 15px 0 0 !important;
    }

    .cardOverlay {
        border-radius: 0 0 0 15px !important;
    }

    .eventsCardStyle:hover .cardOverlay {
        width: 100%;
        border-radius: 0 0 15px 15px !important;
    }

    h1 {
        font-size: 10vw !important;
        line-height: 120%;
    }
}

@media screen and (max-width: 326px) {

    .sectorList {
        grid-template-columns: 1fr !important;
    }
}

@media screen and (max-width: 313px) {

    .eventsPageContainer {
        padding: 80px 0 0 0 !important;
    }
}