
.whatWeDoSectionContainer1 {
    width: 100%;
    height: auto;
}

.whatWeDoSectionContainer2, .whatWeDoSectionContainer3, .whatWeDoSectionContainer4, .whatWeDoSectionContainer5, .whatWeDoSectionContainer6, .whatWeDoSectionContainer7, .whatWeDoSectionContainer8, .whatWeDoSectionContainer9, .whatWeDoSectionContainer10 {
    position: relative;
    width: 100%;
    height: auto;
}

.whatWeDoSectionContainer1 {
    top: 0px;
    padding: 0px 0 50px 0;
}

.whatWeDoSectionContainer2, .whatWeDoSectionContainer3, .whatWeDoSectionContainer4, .whatWeDoSectionContainer5, .whatWeDoSectionContainer6, .whatWeDoSectionContainer7, .whatWeDoSectionContainer8, .whatWeDoSectionContainer9, .whatWeDoSectionContainer10 {
    padding: 50px 0 50px 0;
}

.whatWeDoSection1, .whatWeDoSection2, .whatWeDoSection3, .whatWeDoSection4, .whatWeDoSection5, .whatWeDoSection6, .whatWeDoSection7, .whatWeDoSection8, .whatWeDoSection9, .whatWeDoSection10 {
    position: relative;
    max-width: 1440px;
    padding: 0% 5% 0% 5%;
    margin: 50px auto;
}

.whatWeDoSection1, .whatWeDoSection2, .whatWeDoSection3, .whatWeDoSection4, .whatWeDoSection5, .whatWeDoSection6, .whatWeDoSection7, .whatWeDoSection8, .whatWeDoSection9, .whatWeDoSection10 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5em;
    align-items: initial;
}

.whatWeDoSection1 {
    z-index: 4;
    /*margin-bottom: -115px*/
}

.whatWeDoSection2 {
    z-index: 3;
}

.whatWeDoSection3 {
    z-index: 2;
}

.whatWeDoSection4 {
    z-index: 1;
}

.whatWeDoImage1, .whatWeDoImage2, .whatWeDoImage3, .whatWeDoImage4, .whatWeDoImage5, .whatWeDoImage6, .whatWeDoImage7, .whatWeDoImage8, .whatWeDoImage9, .whatWeDoImage10 {
    position: absolute;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.whatWeDoImage1, .whatWeDoImage2 {
    height: calc(100% + 160px);
}

/*.whatWeDoImage4, .whatWeDoImage5, .whatWeDoImage6, .whatWeDoImage7, .whatWeDoImage8, .whatWeDoImage9, .whatWeDoImage10 {
    height: 400px;
}*/
@media screen and (max-width: 3000px) {
    .whatWeDoSection1 {
        /*margin-bottom: -80px;*/
    }
}

@media screen and (max-width: 1920px) {
    .whatWeDoSection1 {
        /*margin-bottom: -100px;*/
    }
}

@media screen and (max-width: 1360px) {
    .whatWeDoSection1 {
        /*margin-bottom: -80px;*/
    }
}

@media screen and (max-width: 1284px) {
    .whatWeDoSection1 {
       /* margin-bottom: 50px;*/
    }
}

@media screen and (max-width: 991px) {
    .whatWeDoSection1 {
        margin-bottom: 0 !important;
    }
}

@media screen and (max-width: 768px) {

    .whatWeDoSection1, .whatWeDoSection2, .whatWeDoSection3, .whatWeDoSection4, .whatWeDoSection5, .whatWeDoSection6, .whatWeDoSection7, .whatWeDoSection8, .whatWeDoSection9, .whatWeDoSection10 {
        grid-template-columns: 1fr !important;
        grid-gap: 2em !important;
    }

    .whatWeDoSection1, .whatWeDoSection2, .whatWeDoSection3, .whatWeDoSection4, .whatWeDoSection5, .whatWeDoSection6, .whatWeDoSection7, .whatWeDoSection8, .whatWeDoSection9, .whatWeDoSection10 {
        margin: 0px auto !important;
    }

    .whatWeDoImage1, .whatWeDoImage2, .whatWeDoImage3, .whatWeDoImage4, .whatWeDoImage5, .whatWeDoImage6, .whatWeDoImage7, .whatWeDoImage8, .whatWeDoImage9, .whatWeDoImage10 {
        position: relative;
        /*display: grid;*/
        height: 400px;
    }

    .whatWeDoImage2, .whatWeDoImage4 {
        grid-row: 2/3 !important;
    }

 /*   .whatWeDoImage1 {
        position: relative;
        width: 100%;
        left: 0;
    }

    .whatWeDoImage1, .whatWeDoImage2, .whatWeDoImage3 {
        height: 400px !important;
    }

    .whatWeDoImage2, .whatWeDoImage4 {
        grid-row: 2/3 !important;
    }*/
}

@media screen and (max-width: 425px) {

    .whatWeDoImage1, .whatWeDoImage2, .whatWeDoImage3, .whatWeDoImage4, .whatWeDoImage5, .whatWeDoImage6, .whatWeDoImage7, .whatWeDoImage8, .whatWeDoImage9, .whatWeDoImage10 {
        height: 250px !important;
    }

    h1 {
        font-size: 10vw !important;
        line-height: 120%;
    }
}
